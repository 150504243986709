import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import ModuleButton from "../common/ModuleButton";

export default function InputCapture(props) {
    const handleCancel = () => {
      props.setOpen(false);
      props.setDialog(false);
      props.setErrors(false);
    };

    const handleChange = (value) => {
        props.dialogChange({ name: value });
    };

    return (
        <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.info}
          </DialogContentText>
          <TextField
            autoFocus
            label={(props.errors && props.errors.name) || ""}
            name="name"
            value={props.dialogData.name || ""}
            fullWidth={true}
            error={props.errors && props.errors.name || false}
            onChange={(e) => handleChange(e.target.value)}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <ModuleButton
            btnIcon="check"
            text="submit"
            style={{
              background: "green",
              color: "white",
              marginRight: 10
            }}
            tip="Submit"
            onClick={() => props.handleSubmit()}
          />
          <ModuleButton
            btnIcon="close"
            text="cancel"
            style={{
              background: "red",
              color: "white",
              marginRight: 10
            }}
            tip="Cancel"
            onClick={() => handleCancel()}
          />
        </DialogActions>
      </Dialog>
    );
}
