import React from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Grid, InputAdornment, FormHelperText } from "@mui/material";
import ModuleButton from "../common/ModuleButton";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from '@mui/icons-material/Delete';
import Currency from "currency.js";

export default function OutputLines(props) {
    const handleAddOption = () => {
        const newData = props.output_lines;
        newData.push({ manual: true });

        props.productChange({
            changed: true,
            output_lines: newData
        });
    };

    const handleRemoveOption = (key) => {
        const newData = props.output_lines;
        newData.splice(key, 1);

        props.productChange({
            changed: true,
            output_lines: newData
        });
    };

    const handleLineChange = (event, key) => {
        const newData = props.output_lines;
    
        newData[key][event.target.name] = event.target.value;

        props.productChange({
            changed: true,
            output_lines: newData
        });
    };

    const handleLineCalcChange = (event, key) => {
        const GBP = value => Currency(value, { symbol: '£', decimal: '.', separator: ',' });
        const newData = props.output_lines;

        const value = (event.target.value.indexOf(".") >= 0)
            ? (event.target.value.substr(0, event.target.value.indexOf(".")) + event.target.value.substr(event.target.value.indexOf("."), 3))
            : event.target.value;

        newData[key][event.target.name] = value;

        const cost = GBP(newData[key].unit_price);
        const work = GBP(cost).multiply(newData[key].quantity);
        
        newData[key]['price'] = work.value;

        props.productChange({
            changed: true,
            output_lines: newData
        });
    };


    return (
        <Box sx={{ border: 1, borderColor: "lightgray", borderRadius: 1 }}>
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography sx={{ mt: 1, ml: 2 }} variant="subtitle1" component="div">
                        Output Lines
                    </Typography>
                </Grid>
                <Grid item>
                    {!props.uneditable &&
                        <ModuleButton
                            btnIcon="add"
                            style={{
                                marginTop: 10,
                                marginRight: 10,
                                background: "red",
                                color: "white"
                            }}
                            tip="Add Dynamic Item"
                            size="small"
                            onClick={() => handleAddOption()}
                        />
                    }
                </Grid>
            </Grid>

            <TableContainer>
                <Table aria-label="output lines table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">CC</TableCell>
                            <TableCell align="left">PC</TableCell>
                            <TableCell align="left">Min. Term</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Unit Price</TableCell>
                            <TableCell align="left">Qty</TableCell>
                            <TableCell align="left">Total Price</TableCell>
                            <TableCell align="left">Margin</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.output_lines.map((item, key) => {
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row" width="15%">
                                        <FormControl
                                            error={props.errors && props.errors.charge_code_id || false}
                                            fullWidth
                                        >
                                            <Select
                                                variant="standard"
                                                name="charge_code_id"
                                                value={item.charge_code_id || ""}
                                                onChange={(evt) => props.uneditable || handleLineChange(evt, key)}
                                                inputProps={{ readOnly: props.uneditable }}
                                                InputLabelProps={{ shrink: false }}
                                                size="small"
                                            >
                                                {props.charges && props.charges.map((item, key) => {
                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{props.errors && props.errors.charge_code_id || ""}</FormHelperText>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left" width="10%">
                                        <FormControl
                                            error={props.errors && props.errors.periodic_code_id || false}
                                            fullWidth
                                        >
                                            <Select
                                                variant="standard"
                                                name="periodic_code_id"
                                                value={item.periodic_code_id || ""}
                                                onChange={(evt) => props.uneditable || handleLineChange(evt, key)}
                                                inputProps={{ readOnly: props.uneditable || !item.manual }}
                                                InputLabelProps={{ shrink: false }}
                                                size="small"
                                            >
                                                {props.periods && props.periods.map((item, key) => {
                                                    return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                                })}
                                            </Select>
                                            <FormHelperText>{props.errors && props.errors.periodic_code_id || ""}</FormHelperText>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="minimum_term"
                                            type="number"
                                            value={item.minimum_term || ""}
                                            error={props.errors && props.errors.minimum_term || false}
                                            helperText={props.errors && props.errors.minimum_term || ""}
                                            onChange={(evt) => props.uneditable || handleLineChange(evt, key)}
                                            fullWidth
                                            inputProps={{ step: "1", min: "0" }}
                                            InputProps={{ readOnly: props.uneditable }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="35%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="description"
                                            type="text"
                                            value={item.description || ""}
                                            error={props.errors && props.errors.description || false}
                                            helperText={props.errors && props.errors.description || ""}
                                            onChange={(evt) => props.uneditable || handleLineChange(evt, key)}
                                            fullWidth
                                            InputProps={{ readOnly: props.uneditable }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="10%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="unit_price"
                                            type="number"
                                            value={item.unit_price || ""}
                                            error={props.errors && props.errors.unit_price || false}
                                            helperText={props.errors && props.errors.unit_price || ""}
                                            onChange={(evt) => props.uneditable || !item.manual || handleLineCalcChange(evt, key)}
                                            fullWidth
                                            inputProps={{ step: "0.01", min: "0" }}
                                            InputProps={{ readOnly: props.uneditable || !item.manual }}
                                            size="small"
                                            disabled={item.dynamic}
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="quantity"
                                            type="number"
                                            value={item.quantity  || ""}
                                            error={props.errors && props.errors.quantity || false}
                                            helperText={props.errors && props.errors.quantity || ""}
                                            onChange={(evt) => props.uneditable || !item.manual || handleLineCalcChange(evt, key)}
                                            fullWidth
                                            inputProps={{ min: "0" }}
                                            InputProps={{ readOnly: props.uneditable || !item.manual }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="10%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="price"
                                            type="text"
                                            value={item.dynamic ? "Dynamic" : (item.price || "")}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="margin"
                                            type="number"
                                            value={item.margin || ""}
                                            fullWidth
                                            size="small"
                                            disabled={true}
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="right" width="5%">
                                        {!props.uneditable && item.manual &&
                                            <Tooltip title="Delete option">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => handleRemoveOption(key)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </TableCell>

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
