import React from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import { Box, Checkbox, TextField, Typography } from "@mui/material";
import ModuleFunctions from "./ModuleFunctions";
import Currency from "currency.js";

export default function BopLines(props) {
    const handleCheckbox = (type, index) => {
        const GBP = value => Currency(value, { symbol: '£', decimal: '.', separator: ',' });

        const newData = props.data;
        const idName = (type === "components") ? "component_id" : "labour_id";
 
        const newObj = { ...newData[`${type}`][index] };
        newObj[`${idName}`] = newData[`${type}`][index].id;

        if(type === "components") {
            newObj['description'] = newData[`${type}`][index].material_name;
            newObj['unit_cost'] = newData[`${type}`][index].material_cost;

            delete newObj.material_name;
            delete newObj.material_cost;
        } else {
            newObj['unit_cost'] = newData[`${type}`][index].rate;
            
            delete newObj.rate;
        }
        
        const dec = parseFloat(newObj.markup) / 100;
        const step = GBP(1).add(dec);
        newObj['margin'] = GBP(dec).divide(step).multiply(100).value;

        delete newObj.id;
        delete newObj.product_id;
        delete newObj.material_cat_id;
        delete newObj.material_cat_name;
        delete newObj.writeoff_length;
        delete newObj.writeoff_pc_id;
        delete newObj.product_assign_rate;
        delete newObj.output;
        delete newObj.active;

        if(newData[`${type}`][index].output) {
            newData[`${type}`][index]["output"] = false;
            const key = newData.output_lines.findIndex(element => (element[`${idName}`] === newData[`${type}`][index].id));

            if(key >= 0) {
                newData.output_lines.splice(key, 1);
            }
        } else {
            newData[`${type}`][index]["output"] = true;

            if(!Array.isArray(newData.output_lines)) {
                newData.output_lines = [];    
            }
            
            newData.output_lines.push(newObj);
        }

        newData['changed'] = true;

        props.productChange(newData);
    }


    return (
        <Box sx={{ border: 1, borderColor: "lightgray", borderRadius: 1 }}>
            <Typography sx={{ mt: 1, ml: 2 }} variant="subtitle1" component="div">
                If you would like to directly add any of these lines to the Product Schedule then select them in the list below.
            </Typography>

            <TableContainer>
                <Table aria-label="bop lines table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Output Line?</TableCell>
                            <TableCell align="left">Periodic Code</TableCell>
                            <TableCell align="left">Min. Term</TableCell>
                            <TableCell align="left">Qty</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Total</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.data.components && props.data.components.map((item, key) => {
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row" width="5%">
                                                    <Checkbox
                                                        checked={item.output || false}
                                                        onChange={(e) => props.uneditable || handleCheckbox("components", key)}
                                                    />
                                    </TableCell>
                                    <TableCell align="left" width="15%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="periodic_code_id"
                                            value={ModuleFunctions.getCodeName({ id: item.periodic_code_id, codesArray: props.periods })}
                                            type="text"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="minimum_term"
                                            type="number"
                                            value={item.minimum_term || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="quantity"
                                            type="number"
                                            value={item.quantity  || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="55%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="material_name"
                                            type="text"
                                            value={item.material_name || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="15%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="price"
                                            type="text"
                                            value={item.dynamic ? "Dynamic" : (item.price || "")}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>

                                </TableRow>
                            )
                        })}

                        {props.data.labour_costs && props.data.labour_costs.map((item, key) => {
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row" width="5%">
                                                    <Checkbox
                                                        checked={item.output || false}
                                                        onChange={(e) => props.uneditable || handleCheckbox("labour_costs", key)}
                                                    />
                                    </TableCell>
                                    <TableCell align="left" width="15%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="periodic_code_id"
                                            value={ModuleFunctions.getCodeName({ id: item.periodic_code_id, codesArray: props.periods })}
                                            type="text"
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="minimum_term"
                                            type="number"
                                            value={item.minimum_term || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="5%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="quantity"
                                            type="number"
                                            value={item.quantity || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="55%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="description"
                                            type="text"
                                            value={item.description || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>
                                    <TableCell align="left" width="15%">
                                        <TextField
                                            hiddenLabel
                                            variant="standard"
                                            name="price"
                                            type="number"
                                            value={item.price || ""}
                                            fullWidth
                                            InputProps={{ readOnly: true }}
                                            size="small"
                                        ></TextField>
                                    </TableCell>

                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}
