import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CheckIcon from '@mui/icons-material/Check';
import ImageIcon from '@mui/icons-material/Image';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";

/**
 * 
 * @param boolean   admin
 * @param object    context
 * @param integer   object_id
 * @param string    object_type
 * @param object    references
 * @param object    linkedDocs
 * @param object    tags
 * @returns a component that contains the related resources listings and associated action buttons.
 */
export default function RelatedResources(props) {

    const StructureList = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: 5,
        margin: 10
    }));

    return (
        <Box sx={{ flexGrow: 1, mt: 3, borderRadius: 1, backgroundColor: "lightgray" }}>
            <Grid container spacing={0}>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 1 }} align="center" variant="h6" component="div">
                        Terms and Conditions / Schedules
                    </Typography>
                    <StructureList>
                        <List dense={true}>
                            {props.references && props.references.map((item, key) => {
                                return (
                                    <ListItem
                                        secondaryAction={
                                            !props.uneditable && (
                                                <Tooltip title="Delete document reference">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={() => ModuleFunctions.handleDeleteReference({ id: item.id, triggerChange: props.triggerChange })}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <LibraryBooksIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.document_ref+': '+item.document_title}
                                            secondary={item.section_id ? ('specifically section '+item.section_index) : ""}
                                        />  
                                    </ListItem>
                                )
                            })}
                            {!props.uneditable && (
                                <ListItem>
                                    <ModuleButton
                                        btnIcon="add"
                                        text="add reference"
                                        style={{
                                            background: props.context.theme.sidebar.background,
                                            color: "white"
                                        }}
                                        tip="Add a schedule reference"
                                        onClick={() => props.dialogChange({
                                            object_id: props.object_id,
                                            object_type: props.object_type,
                                            document_id: null,
                                            document_ref: null,
                                            document_title: null,
                                            section_id: null,
                                            section_index: null
                                        })}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </StructureList>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 1 }} align="center" variant="h6" component="div">
                        Related Resources
                    </Typography>
                    <StructureList>
                        <List dense={true}>
                            {props.linkedDocs && props.linkedDocs.map((item, key) => {
                                return (
                                    <ListItem
                                        secondaryAction={
                                            item.visibility_name === "Admin" && !props.admin ? (
                                                null
                                            ) : (
                                                <Stack direction="row" alignItems="right" spacing={0}>
                                                    <Tooltip title="View linked document">
                                                        <IconButton
                                                            aria-label="view"
                                                            onClick={() => ModuleFunctions.handleView({ id: item.vault_id })}
                                                        >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {`${item.type}`.includes("image") && (
                                                        item.primary ? (
                                                            <Tooltip title="Primary image">
                                                                <IconButton
                                                                    aria-label="primary image"
                                                                    color="success"
                                                                >
                                                                    <ImageIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Set as primary image">
                                                                <IconButton
                                                                    aria-label="set as primary image"
                                                                    onClick={() => props.uneditable || ModuleFunctions.handlePrimary({
                                                                        object_id: props.object_id,
                                                                        object_type: props.object_type,
                                                                        id: item.id,
                                                                        triggerChange: props.triggerChange
                                                                    })}
                                                                >
                                                                    <ImageIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )
                                                    )}
                                                    {!props.uneditable && (
                                                        <Tooltip title="Delete linked document">
                                                            <IconButton
                                                                edge="end"
                                                                aria-label="delete"
                                                                onClick={() => ModuleFunctions.handleUnlink({
                                                                    object_id: props.object_id,
                                                                    object_type: props.object_type,
                                                                    id: item.id,
                                                                    triggerChange: props.triggerChange
                                                                })}
                                                            >
                                                                <LinkOffIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            )
                                        }
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                {item.approved
                                                    ? <Tooltip title="Document approved"><CheckIcon /></Tooltip>
                                                    : <Tooltip title="Document awaiting approval"><QuestionMarkIcon /></Tooltip>
                                                }
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item.name}
                                            secondary={item.description}
                                        />  
                                    </ListItem>
                                )
                            })}
                            {!props.uneditable && (
                                <ListItem>
                                <ModuleButton
                                    btnIcon="add"
                                    text="link document"
                                    style={{
                                        background: props.context.theme.sidebar.background,
                                        color: "white"
                                    }}
                                    tip="Add a document link"
                                    onClick={() => props.mediaChange(true)}
                                />
                                </ListItem>
                            )}
                        </List>
                    </StructureList>
                </Grid>
            </Grid>
        </Box>
    )

}
