import React from "react";
import BopLines from "../modules/BopLines";

export default function BillOfProduct(props) {

    return (
        <div className="block">
            <div className="container-fluid">
                <div style={{ width: '100%' }}>
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h4>Bill Of Product</h4>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <BopLines
                                uneditable={props.uneditable}
                                data={props.data}
                                periods={props.periods}
                                productChange={props.productChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
