import React, { useState, useEffect } from "react";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, TextField, InputAdornment } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import ModuleFunctions from "./ModuleFunctions";
import ModuleButton from "../common/ModuleButton";

export default function LabourSingle(props) {
    const [positions, setPositions] = useState(false);

    const positionsChange = (data) => {
        setPositions(data);
    };

    const handleCancel = () => {
        props.setOpen(false);
        props.setDialog(false);
        props.setErrors(false);
    };

    useEffect(() => {
        ModuleFunctions.getPositions({ positionsChange: positionsChange });
    }, []);


    if(props.dialogData && positions) {
        return (
            <div>
                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={props.open}
                    onClose={handleCancel}
                    scroll={"paper"}
                    style={{ padding: "15px" }}>
                    <DialogTitle
                        style={{
                            background: props.context.theme.sidebar.background,
                            color: "white",
                        }}>
                        Product {props.title}
                    </DialogTitle>
                    <DialogContent>
                        <div className="form-row">
                            <div className="form-group-sm col-lg-12">
                                <TextField
                                    label="Description"
                                    name="description"
                                    type="text"
                                    value={props.dialogData.description || ""}
                                    error={props.errors && props.errors.description || false}
                                    helperText={props.errors && props.errors.description || ""}
                                    onChange={(e) => props.uneditable || ModuleFunctions.handleLabourChange({
                                        name: e.target.name,
                                        value: e.target.value,
                                        labourChange: props.labourChange
                                    })}
                                    fullWidth
                                    InputProps={{ readOnly: props.uneditable }}
                                    size="small"
                                ></TextField>
                            </div>
                        </div>
                        
                        
    
                        <div className="form-row">
                            <div className="form-group-sm col-lg-6">
                                <FormControl error={props.errors && props.errors.position_id || false} fullWidth>
                                    <InputLabel>Role</InputLabel>
                                        <Select
                                            label="Role"
                                            name="position_id"
                                            value={props.dialogData.position_id || ""}
                                            onChange={(evt, labObj) => props.uneditable || ModuleFunctions.handlePositionChange({
                                                event: evt,
                                                element: labObj,
                                                labourChange: props.labourChange
                                            })}
                                            inputProps={{ readOnly: props.uneditable }}
                                            size="small"
                                        >
                                            {positions && positions.map((item, key) => {
                                                return <MenuItem key={key} value={item.position_id} labObj={item}>{item.name}</MenuItem>
                                            })}
                                        </Select>
                                    <FormHelperText>{props.errors && props.errors.position_id || ""}</FormHelperText>
                                </FormControl>
                            </div>

                            <div className="form-group-sm col-lg-3">
                                <FormControl error={props.errors && props.errors.periodic_code_id || false} fullWidth>
                                    <InputLabel>Periodic Code</InputLabel>
                                        <Select
                                            label="Periodic Code"
                                            name="periodic_code_id"
                                            value={props.dialogData.periodic_code_id || ""}
                                            onChange={(e) => props.uneditable || ModuleFunctions.handleLabourChange({
                                                name: e.target.name,
                                                value: e.target.value,
                                                labourChange: props.labourChange
                                            })}
                                            inputProps={{ readOnly: props.uneditable }}
                                            size="small"
                                        >
                                            {props.periods && props.periods.map((item, key) => {
                                                return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                            })}
                                        </Select>
                                    <FormHelperText>{props.errors && props.errors.periodic_code_id || ""}</FormHelperText>
                                </FormControl>
                            </div>
                                
                            <div className="form-group-sm col-lg-3">
                                <TextField
                                    label="Quantity"
                                    name="quantity"
                                    type="number"
                                    value={props.dialogData.quantity || ""}
                                    error={props.errors && props.errors.quantity || false}
                                    helperText={props.errors && props.errors.quantity || ""}
                                    onChange={(e) => props.uneditable || ModuleFunctions.handleLabourCalcChange({
                                        name: e.target.name,
                                        value: e.target.value,
                                        data: props.dialogData,
                                        labourChange: props.labourChange
                                    })}
                                    fullWidth
                                    inputProps={{ min: "0" }}
                                    InputProps={{ readOnly: props.uneditable }}
                                    size="small"
                                ></TextField>
                            </div>
                        </div>
    
                        <div className="form-row">
                            <div className="form-group-sm col-lg-3">
                                <TextField
                                    label="Rate"
                                    name="rate"
                                    type="number"
                                    value={props.dialogData.rate || ""}
                                    error={props.errors && props.errors.rate || false}
                                    helperText={props.errors && props.errors.rate || ""}
                                    onChange={(e) => props.uneditable || ModuleFunctions.handleLabourCalcChange({
                                        name: e.target.name,
                                        value: e.target.value,
                                        data: props.dialogData,
                                        labourChange: props.labourChange
                                    })}
                                    fullWidth
                                    inputProps={{ step: "0.01", min: "0" }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        readOnly: props.uneditable
                                    }}
                                    size="small"
                                ></TextField>
                            </div>
    
                            <div className="form-group-sm col-lg-3">
                                <TextField
                                    label="Markup"
                                    name="markup"
                                    type="number"
                                    value={props.dialogData.markup || ""}
                                    error={props.errors && props.errors.markup || false}
                                    helperText={props.errors && props.errors.markup || ""}
                                    onChange={(e) => props.uneditable || ModuleFunctions.handleLabourCalcChange({
                                        name: e.target.name,
                                        value: e.target.value,
                                        data: props.dialogData,
                                        labourChange: props.labourChange
                                    })}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        readOnly: props.uneditable
                                    }}
                                    size="small"
                                ></TextField>
                            </div>
    
                            <div className="form-group-sm col-lg-3">
                                <TextField
                                    label="Unit Price"
                                    name="unit_price"
                                    type="number"
                                    value={props.dialogData.unit_price || ""}
                                    error={props.errors && props.errors.unit_price || false}
                                    helperText={props.errors && props.errors.unit_price || ""}
                                    fullWidth
                                    inputProps={{ step: "0.01", min: "0" }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        readOnly: true
                                    }}
                                    size="small"
                                ></TextField>
                            </div>
    
                            <div className="form-group-sm col-lg-3">
                                <TextField
                                    label="Total Price"
                                    name="price"
                                    type="number"
                                    value={props.dialogData.price || ""}
                                    error={props.errors && props.errors.price || false}
                                    helperText={props.errors && props.errors.price || ""}
                                    onChange={(e) => props.uneditable || ModuleFunctions.handleLabourCalcChange({
                                        name: e.target.name,
                                        value: e.target.value,
                                        data: props.dialogData,
                                        labourChange: props.labourChange
                                    })}
                                    fullWidth
                                    inputProps={{ step: "0.01", min: "0" }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                        readOnly: props.uneditable
                                    }}
                                    size="small"
                                ></TextField>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {!props.uneditable &&
                            <ModuleButton
                                btnIcon="check"
                                text="submit"
                                style={{
                                    background: "green",
                                    color: "white",
                                    marginRight: 10
                                }}
                                tip="Submit"
                                onClick={() => {
                                    props.handleSubmit();
                                }}
                            />
                        }
                        <ModuleButton
                            btnIcon="close"
                            text="cancel"
                            style={{
                                background: "red",
                                color: "white",
                                marginRight: 10
                            }}
                            tip="Cancel"
                            onClick={handleCancel}
                        />
                    </DialogActions>
                </Dialog>
            </div>
        );
    } else {
        return <></>;
    }   
}
