import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@mui/material";
import ModuleButton from "../common/ModuleButton";

export default function NotificationCapture(props) {
    const handleCancel = () => {
        props.setOpen(false);
        props.setDialog(false);
        props.setErrors(false);
    };

    const handleChange = async (event, element) => {
        props.dialogChange({
            contact_id: event.target.value,
            uuid: element.props.docObj.uuid,
            name: element.props.docObj.name,
            sbu_uuid: element.props.docObj.group_uuid,
            sbu_name: element.props.docObj.group_name
        });
    };

    return (
        <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.info}
            </DialogContentText>
            <FormControl error={props.errors && props.errors.contact_id || false} fullWidth margin="dense">
                <InputLabel>Person</InputLabel>
                <Select
                    label="Person"
                    name="contact_id"
                    value={props.dialogData.contact_id || ""}
                    onChange={(evt, docObj) => handleChange( evt, docObj )}
                >
                    {props.personnel && props.personnel.map((item, key) => {
                        return <MenuItem key={key} value={item.contact_id} docObj={item}>{item.name}</MenuItem>
                    })}
                </Select>
                <FormHelperText>{props.errors && props.errors.contact_id || ""}</FormHelperText>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <ModuleButton
            btnIcon="check"
            text="submit"
            style={{
              background: "green",
              color: "white",
              marginRight: 10
            }}
            tip="Submit"
            onClick={() => props.handleSubmit()}
          />
          <ModuleButton
            btnIcon="close"
            text="cancel"
            style={{
              background: "red",
              color: "white",
              marginRight: 10
            }}
            tip="Cancel"
            onClick={() => handleCancel()}
          />
        </DialogActions>
      </Dialog>
    );
}
