import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@mui/material";
import ModuleButton from "../common/ModuleButton";

export default function ScheduleCapture(props) {
    const [sections, setSections] = useState(false);

    const handleCancel = () => {
        setSections(false);
        props.setOpen(false);
        props.setDialog(false);
        props.setErrors(false);
    };

    const handleDocChange = (event, element) => {
        setSections(element.props.docObj.menu);
        props.dialogChange({
            document_id: event.target.value,
            document_ref: element.props.docObj.reference,
            document_title: element.props.docObj.title
        });
    };

    const handleSecChange = (event, element) => {
        props.dialogChange({
            section_id: event.target.value,
            section_index: element.props.index
        });
    };

    return (
        <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.info}
            </DialogContentText>
            <FormControl error={props.errors && props.errors.document_id || false} fullWidth margin="dense">
                <InputLabel>Document</InputLabel>
                <Select
                    label="Document"
                    name="document_id"
                    value={props.dialogData.document_id || ""}
                    onChange={(evt, docObj) => handleDocChange( evt, docObj )}
                >
                    {props.schedules && props.schedules.map((item, key) => {
                        return <MenuItem key={key} value={item.id} docObj={item}>{item.reference+': '+item.title}</MenuItem>
                    })}
                </Select>
                <FormHelperText>{props.errors && props.errors.document_id || ""}</FormHelperText>
            </FormControl>
            <FormControl error={props.errors && props.errors.section_id || false} fullWidth margin="dense">
                <InputLabel>Section</InputLabel>
                <Select
                    label="Section"
                    name="section_id"
                    value={props.dialogData.section_id || ""}
                    onChange={(evt, secObj) => handleSecChange(evt, secObj)}
                >
                    {sections && sections.map((item, key) => {
                        return <MenuItem key={key} value={item.section} index={item.index}>{item.index}</MenuItem>
                    })}
                </Select>
                <FormHelperText>{props.errors && props.errors.section_id || ""}</FormHelperText>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <ModuleButton
            btnIcon="check"
            text="submit"
            style={{
              background: "green",
              color: "white",
              marginRight: 10
            }}
            tip="Submit"
            onClick={() => props.handleSubmit()}
          />
          <ModuleButton
            btnIcon="close"
            text="cancel"
            style={{
              background: "red",
              color: "white",
              marginRight: 10
            }}
            tip="Cancel"
            onClick={() => handleCancel()}
          />
        </DialogActions>
      </Dialog>
    );
}
