import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import ModuleButton from "../common/ModuleButton";

export default function UploadedDocumentEdit(props) {

    const handleCancel = () => {
        props.setOpen(false);
        props.setDialog(false);
        props.setErrors(false);
    };

    const handleChange = (name, value) => {
        const newData = { ...props.dialogData };
        newData[name] = value;

        props.documentChange(newData);
    };

    return (
        <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.info}
            </DialogContentText>
            <TextField
                autoFocus
                label={(props.errors && props.errors.description) || ""}
                name="description"
                value={props.dialogData.description || ""}
                multiline
                minRows={2}
                maxRows={5}
                fullWidth={true}
                error={props.errors && props.errors.description || false}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                margin="dense"
                placeholder="Description"
            />
            <FormControl error={props.errors && props.errors.tag_id || false} fullWidth margin="dense">
                <InputLabel>Tagged As</InputLabel>
                <Select
                    label="Document Tag"
                    name="tag_id"
                    value={props.dialogData.tag_id || ""}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                    {props.tags && props.tags.map((item, key) => {
                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                    })}
                </Select>
                <FormHelperText>{props.errors && props.errors.tag_id || ""}</FormHelperText>
            </FormControl>
            <FormControl error={props.errors && props.errors.visibility_id || false} fullWidth margin="dense">
                <InputLabel>Document Visibility</InputLabel>
                <Select
                    label="Document Visibility"
                    name="visibility_id"
                    value={props.dialogData.visibility_id || ""}
                    onChange={(e) => handleChange(e.target.name, e.target.value)}
                >
                    {props.visibilities && props.visibilities.map((item, key) => {
                        return <MenuItem key={key} value={item.id}>{item.value}</MenuItem>
                    })}
                </Select>
                <FormHelperText>{props.errors && props.errors.visibility_id || ""}</FormHelperText>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <ModuleButton
            btnIcon="check"
            text="submit"
            style={{
              background: "green",
              color: "white",
              marginRight: 10
            }}
            tip="Submit"
            onClick={() => props.handleSubmit()}
          />
          <ModuleButton
            btnIcon="close"
            text="cancel"
            style={{
              background: "red",
              color: "white",
              marginRight: 10
            }}
            tip="Cancel"
            onClick={() => handleCancel()}
          />
        </DialogActions>
      </Dialog>
    );
}
